import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Form,
  FormControl,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  Checkbox,
} from 'react-bootstrap';
import {
  Dropdown,
  DropdownItem,
  DropdownToggle,
} from '@patternfly/react-core/deprecated';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import { saveAs } from 'file-saver';
import Spinner from 'react-spinkit';
import * as merge from 'deepmerge';
import { withTranslation } from 'react-i18next';
import { getLanguageInfo } from '../../services/LanguageService';

import Store from './store';
import { alert } from '../../services/NotificationService';
import { TRAINING_CONTACTS, MANAGE_ACCOUNT } from '../../config/constants';
import './styles.scss';

@inject('userStore')
@withTranslation()
@observer
class EBook extends Component {
  static showGeneratePDFMessage(t, modality, isRHUSubscriber) {
    const lowerModality = modality.toLowerCase();
    let html;

    if (
      lowerModality === 'vt' ||
      lowerModality === 'ilt' ||
      lowerModality === 'os'
    ) {
      html = t(`<p>Generating student guide. This may take some time.</p>`);
    } else if (isRHUSubscriber) {
      html = t(`<p>Generating student guide. This may take some time.</p> \
        <p>Downloads are limited to one (1) course manual per day.</p>`);
    } else {
      html = t(`<p>Generating student guide. This may take some time.</p> \
        <p>Downloads are limited to one (1) course manual per day, and no more than ten (10) \
        different course manuals during the term of a Red Hat Learning Subscription.</p>`);
    }

    alert('info', html);
  }

  static propTypes = {
    t: PropTypes.func.isRequired,
    course: PropTypes.object,
    user: PropTypes.object,
    disabled: PropTypes.bool,
    modality: PropTypes.string,
    userStore: MobXPropTypes.observableObject.isRequired,
  };

  static defaultProps = {
    modality: '',
    course: {
      code: '',
      version: '',
    },
    user: {
      firstName: '',
      lastName: '',
      email: '',
    },

    disabled: false,
  };

  constructor(props) {
    super(props);

    this.store = new Store();
  }

  componentDidMount() {
    const { course, modality } = this.props;
    const { code, version } = course;

    const { user } = this.props.userStore;
    // eslint-disable-next-line
    const preferredEmail = user?.email || user?.default_email;

    this.store.user = merge(user, {
      firstName: user.first_name,
      lastName: user.last_name,
      email: preferredEmail,
    });

    this.store.list(code, version, modality);

    this.checkPDF = this.checkPDF.bind(this);
    this.showModal = this.showModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onProceed = this.onProceed.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onCheck = this.onCheck.bind(this);
    this.verifyUser = this.verifyUser.bind(this);
  }

  componentWillReceiveProps = (nextProps) => {
    if (
      nextProps.course.code !== this.props.course.code ||
      nextProps.course.version !== this.props.course.version
    ) {
      this.store.list(nextProps.course.code, nextProps.course.version);
    }
  };

  componentWillUnmount() {
    // TODO: Kill the poll on unmount
  }

  onSubmit(e) {
    e.preventDefault();
    const { isRHUSubscriber } = this.props.userStore;
    EBook.showGeneratePDFMessage(
      this.props.t,
      this.props.modality,
      isRHUSubscriber,
    );
    this.checkPDF(this.store.currentUUID);
    this.closeModal();
    this.store.isTermsAndConditionsEnabled = false;
    this.store.isTermsAndConditionsAccepted = false;
  }

  onProceed(e) {
    e.preventDefault();
    this.store.isTermsAndConditionsEnabled = true;
  }

  onChange = (e) => {
    const user = { ...this.store.user };
    user[e.target.name] = e.target.value;

    this.store.user = user;
  };

  onCheck = () => {
    this.store.isTermsAndConditionsAccepted =
      !this.store.isTermsAndConditionsAccepted;
  };

  checkPDF(uuid) {
    const { course, t, modality } = this.props;

    this.store
      .checkPDF(uuid, modality)
      .then(() => {
        this.store
          .fetchPDF(uuid, null, modality)
          .then((response) => {
            if (response.status === 200) {
              const blob = new Blob([response.data], {
                type: 'application/pdf',
              });

              saveAs(
                blob,
                `${course.code}-${course.version}-student-guide.pdf`,
              );
              this.store.loading = false;
              alert('success', t('Student Guide downloaded'), false);
            }

            if (response.status === 202) {
              Store.poll()
                .then(() => this.checkPDF(uuid))
                .catch((error) => {
                  console.warn(error);
                });
              this.store.loading = true;
            }
          })
          .catch((error) => {
            console.warn(error);
          });
      })
      .catch((error) => {
        this.store.loading = false;

        if (
          !(
            error &&
            error.response &&
            error.response.status &&
            error.response.data
          )
        ) {
          return;
        }

        const { status } = error.response;
        const { code } = error.response.data;

        // Forbidden
        if (status === 403) {
          switch (code) {
            case 'blocked':
              alert(
                'error',
                t(`Student guide downloads have been disabled for your account. \
                Please contact <a href="${TRAINING_CONTACTS}" target="_blank">Red Hat Training Support</a>
                for further assistance.`),
                false,
              );
              break;
            case 'ident_missing':
              alert(
                'error',
                t(`The first name, last name or email is not set on your account. This is required to download
                a student guide.
                Please contact <a href="${TRAINING_CONTACTS}" target="_blank">Red Hat Training Support</a>
                for further assistance.`),
                false,
              );
              break;
            case 'ident_unverified':
              alert(
                'error',
                t(`Your account needs to be verified before you can download a student guide.
                Please contact <a href="${TRAINING_CONTACTS}" target="_blank">Red Hat Training Support</a>
                for further assistance.`),
                false,
              );
              break;
            case 'subscription_quota_exceeded':
              alert(
                'error',
                t(`Only 10 student guides can be downloaded per subscription period. Please contact
                <a href="${TRAINING_CONTACTS}" target="_blank">Red Hat Training Support</a>
                if you feel this is in error.`),
                false,
              );
              break;
            case 'daily_quota_exceeded':
              alert(
                'error',
                t(`Only one student guide can be downloaded per day. Please try again tomorrow or contact
                <a href="${TRAINING_CONTACTS}" target="_blank">Red Hat Training Support</a> with any questions.`),
                false,
              );
              break;
            case 'free_tier':
              alert(
                'error',
                t(`Ebook downloads are not a part of free trial.`),
                false,
              );
              break;
            default:
              alert(
                'error',
                t(
                  'Your session has expired. Please log in to download the student guide.',
                ),
                false,
              );
              break;
          }
          return;
        }

        // Not found
        if (status === 404) {
          switch (code) {
            case 'document_not_found':
              alert(
                'error',
                t(`We were not able to locate the student guide.
                Please contact <a href="${TRAINING_CONTACTS}" target="_blank">Red Hat Training Support</a>
                for further assistance.`),
                false,
              );
              break;
            case 'enrollment_not_found':
              alert(
                'error',
                t(`The enrollment provided could not be found.
                Please contact <a href="${TRAINING_CONTACTS}" target="_blank">Red Hat Training Support</a>
                for further assistance.`),
                false,
              );
              break;
            default:
              alert(
                'error',
                t(`We were not able to locate the student guide.
                Please contact <a href="${TRAINING_CONTACTS}" target="_blank">Red Hat Training Support</a>
                for further assistance.`),
                false,
              );
              break;
          }
          return;
        }

        alert(
          'error',
          t(`There was an error generating the student guide.
          Please contact <a href="${TRAINING_CONTACTS}" target="_blank">Red Hat Training Support</a>
          for further assistance.`),
          false,
        );
      });
  }

  verifyUser(uuid) {
    // Verify required user infomation is available
    const {
      first_name: firstName,
      last_name: lastName,
      default_email: defaultEmail,
      email,
    } = this.props.userStore.user;

    const preferredEmail = email || defaultEmail;

    if (!(firstName && lastName && preferredEmail)) {
      this.store.onUserInfoNotAvailable(
        {
          firstName,
          lastName,
          preferredEmail,
        },
        uuid,
      );
    }
    // Pop up modal asking for missing information
    this.showModal();
  }

  showModal() {
    this.store.modal.opened = true;
  }

  closeModal() {
    this.store.modal.opened = false;
  }

  isProfileInputAllowed() {
    return this.props.userStore.isRHUSubscriber;
  }

  render() {
    const { t } = this.props;
    const { documents, loading, modal, user } = this.store;
    const id = 'ebook-download-btn';

    if (!documents?.length) return null;

    return (
      <Form onSubmit={this.onSubmit} className="ebook-download">
        {documents.length ? (
          <React.Fragment>
            {!loading ? (
              <Dropdown
                toggle={
                  <DropdownToggle
                    toggleVariant="secondary"
                    onToggle={(isOpen) => {
                      this.store.isDropdownOpen = isOpen;
                    }}
                    isDisabled={loading || this.props.disabled}
                  >
                    {t('Download pdf')}
                  </DropdownToggle>
                }
                isOpen={this.store.isDropdownOpen}
                className={id}
                id={id}
                dropdownItems={documents.map((doc) => (
                  <DropdownItem
                    key={doc.uuid}
                    onClick={() => {
                      this.store.currentUUID = doc.uuid;
                      this.verifyUser(doc.uuid);
                    }}
                  >
                    {`${getLanguageInfo(doc.language).localName} (${
                      doc.language
                    })`}
                  </DropdownItem>
                ))}
              />
            ) : (
              <Spinner name="circle" title={t('')} fadeIn="none" />
            )}
          </React.Fragment>
        ) : null}
        <Modal
          show={!this.props.disabled && modal.opened}
          onHide={this.closeModal}
          bsSize="lg"
        >
          <ModalHeader closeButton>
            {!this.store.isTermsAndConditionsEnabled ? (
              <React.Fragment>
                <ModalTitle>
                  {t('Download Student Guide - Step 1/2')}
                </ModalTitle>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <ModalTitle>
                  {t('Download Student Guide - Step 2/2')}
                </ModalTitle>
              </React.Fragment>
            )}
          </ModalHeader>

          <ModalBody>
            {!this.store.isTermsAndConditionsEnabled ? (
              <React.Fragment>
                <p
                  dangerouslySetInnerHTML={{
                    __html: t(
                      'In order to download the Student Guide, you will need to complete your information in your Red Hat account. Please visit  <a href="{{url}}" target="_blank">redhat.com</a> to complete this information. When you log into the Learning Subscription, your First Name, Last Name and Email address will be entered and you will be able to proceed.',
                      { url: MANAGE_ACCOUNT },
                    ),
                  }}
                />
                <FormGroup
                  validationState={
                    user.firstName && user.firstName.length > 0 ? null : 'error'
                  }
                >
                  <FormControl
                    id="EbookFirstName"
                    name="firstName"
                    type="text"
                    autoFocus
                    placeholder={t('First name')}
                    disabled={!this.isProfileInputAllowed()}
                    value={user.firstName}
                    onChange={this.onChange}
                  />
                </FormGroup>
                <FormGroup
                  validationState={
                    user.lastName && user.lastName.length > 0 ? null : 'error'
                  }
                >
                  <FormControl
                    id="EbookLastName"
                    name="lastName"
                    type="text"
                    label={t('Last name')}
                    placeholder={t('Last name')}
                    disabled={!this.isProfileInputAllowed()}
                    value={user.lastName}
                    onChange={this.onChange}
                  />
                </FormGroup>
                <FormGroup
                  validationState={
                    user.email && user.email.length > 0 ? null : 'error'
                  }
                >
                  <FormControl
                    id="EbookEmail"
                    name="email"
                    type="email"
                    label={t('Email address')}
                    placeholder={t('Email address')}
                    disabled={!this.isProfileInputAllowed()}
                    value={user.email}
                    onChange={this.onChange}
                  />
                </FormGroup>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <FormGroup>
                  <Checkbox onChange={this.onCheck} className="tandc-checkbox">
                    {t(
                      'By checking this box, you understand, acknowledge and agree that you are expressly prohibited from reproducing, translating, posting, publishing, transmitting or otherwise disclosing any of the content of this student guide or other course materials used for this course by any means unless authorized by Red Hat in writing. Red Hat exclusively retains all copyrights, trademark rights and other intellectual proprietary rights embodied within the student guide and other course materials used for this course and no rights are granted to you by accessing this student guide or such other materials.',
                    )}
                  </Checkbox>
                </FormGroup>
              </React.Fragment>
            )}
          </ModalBody>
          <ModalFooter>
            {!this.store.isTermsAndConditionsEnabled ? (
              <React.Fragment>
                <Button
                  block
                  bsStyle="primary"
                  type="submit"
                  onClick={this.onProceed}
                  disabled={!(user.firstName && user.lastName && user.email)}
                >
                  {t('Proceed')}
                </Button>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Button
                  block
                  bsStyle="primary"
                  type="submit"
                  onClick={this.onSubmit}
                  disabled={!this.store.isTermsAndConditionsAccepted}
                >
                  {t('Generate')}
                </Button>
              </React.Fragment>
            )}
          </ModalFooter>
        </Modal>
      </Form>
    );
  }
}

export default EBook;
