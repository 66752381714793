import React, { Suspense, useContext, useEffect } from 'react';
import {
  Title,
  TitleSizes,
  Skeleton,
  Button,
  Alert,
} from '@patternfly/react-core';
import { KalturaWidget } from '@ole-ui/ole-ui-components';
import { observer, useLocalStore } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { withErrorBoundary } from 'react-error-boundary';
import PlayIcon from '@patternfly/react-icons/dist/esm/icons/play-icon';
import { storesContext } from 'stores';
import GenericViewErrorV2 from 'components/Error/GenericViewErrorV2';
import { handleError } from 'services/ErrorService';
import { touchProgressDocument } from 'services/ProgressService';
import ViewLoader from 'components/Loading/View';
import Separator from 'components/Separator';
import useKalturaSession from 'hooks/useKalturaSession';
import { Modality } from 'types';
import VersionsDropdown from 'components/Versions/Dropdown';
import TechOverviewStore from './store';

import './styles.scss';

const TechOverview = () => {
  const { t } = useTranslation();
  const { routerStore, catalogStore, configStore } = useContext(storesContext);

  const { slug } = routerStore?.router?.getState().params;
  const [code, version] = slug.split('-');

  const store = useLocalStore(() => new TechOverviewStore(catalogStore, slug));
  const {
    isLoading,
    title,
    videoEntryId: entryId,
    playerPartnerId,
    playerUIConfigId,
    isPlaylist,
  } = store;

  const { kalturaSession, skipKalturaSession } = useKalturaSession({
    entryId,
  });

  const isKalturaSessionResolved = kalturaSession || skipKalturaSession;

  useEffect(() => {
    store.getTechOverview(slug);
    touchProgressDocument(slug);
  }, [slug]);

  useEffect(() => {
    store.setVersions();
  }, [catalogStore.subscriptionCatalog]);

  return (
    <Suspense fallback={<ViewLoader />}>
      <div className="container-grid-responsive tech-overview">
        {store.versions?.length > 0 || !store.payload ? (
          <>
            {isLoading ? (
              <Skeleton
                width="75%"
                fontSize="4xl"
                screenreaderText={t('Loading content')}
              />
            ) : (
              <Title
                className="tech-overview__title"
                headingLevel="h1"
                size={TitleSizes['4xl']}
              >
                {title || t('Techical overview')}
              </Title>
            )}
            <Separator />
            <div className="tech-overview__actions">
              {store.versions?.length > 1 && (
                <VersionsDropdown
                  code={code}
                  currentVersion={version}
                  versions={store.versions}
                  isLatestVersion={store.isLatestVersion}
                  modality={Modality.TechOverview}
                />
              )}
              <Button
                variant="primary"
                component="a"
                target="_blank"
                href={`${configStore.config.SURVEY_URL}?modality=tov&course=${code}`}
                isDisabled={isLoading}
              >
                {t('Feedback')}
              </Button>
            </div>
            {!isLoading && isKalturaSessionResolved ? (
              <KalturaWidget
                className="tech-overview__player"
                partnerId={playerPartnerId}
                playerId={playerUIConfigId}
                entryId={entryId}
                session={kalturaSession}
                isPlaylist={isPlaylist}
              />
            ) : (
              <div className="tech-overview__skeleton-container">
                <PlayIcon className="tech-overview__skeleton-icon" size="lg" />
                <Skeleton
                  height="100%"
                  screenreaderText={t('Loading content')}
                />
              </div>
            )}
          </>
        ) : (
          <Alert variant="danger" title={t('This content is not available.')} />
        )}
      </div>
    </Suspense>
  );
};

export default withErrorBoundary(
  observer(TechOverview),
  GenericViewErrorV2,
  handleError,
);
