import React, { useContext, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '@patternfly/react-core';
import { useTranslation } from 'react-i18next';
import SVG from 'react-inlinesvg';
import { observer } from 'mobx-react';
import CollapseIcon from 'img/icons/web-icon-caret-thin-up.svg';
import ExpandIcon from 'img/icons/web-icon-caret-thin-down.svg';
import useOverallProgressFilters from './useOverallProgressFilters';

import './styles.scss';

const FilterContext = React.createContext();
FilterContext.displayName = 'FilterContext';

function FilterGroup({ children, onFilterChange }) {
  const { t } = useTranslation();
  const [currentFilters, setCurrentFilters] = useState({});

  const handleClear = () => {
    setCurrentFilters({});
    onFilterChange({});
  };

  const setFilterOption = useCallback(
    (filterTypeKey, option, isChecked) => {
      setCurrentFilters((prev) => {
        const nextValue = {
          ...prev,
          [filterTypeKey]: {
            ...(prev[filterTypeKey] || {}),
            [option]: isChecked,
          },
        };
        onFilterChange(nextValue);
        return nextValue;
      });
    },
    [currentFilters, setCurrentFilters],
  );
  return (
    <FilterContext.Provider
      value={{ currentFilters, setCurrentFilters, setFilterOption }}
    >
      <div className="filter-group">
        <div className="filter-group__header">
          <span className="filter-group__title">{t('Filter')}</span>
          <button
            className="filter-group__clear-btn"
            type="button"
            onClick={handleClear}
          >
            {t('Clear')}
          </button>
        </div>
        <div className="filter-group__content">{children}</div>
      </div>
    </FilterContext.Provider>
  );
}

FilterGroup.propTypes = {
  children: PropTypes.node.isRequired,
  onFilterChange: PropTypes.func,
};

FilterGroup.defaultProps = {
  onFilterChange: null,
};

function useFilter() {
  const context = useContext(FilterContext);
  if (context === undefined) {
    throw new Error('useFilter must be used within a <CoursesFilter />');
  }
  return context;
}

const Filter = observer(({ filterTypeKey, title }) => {
  const { t } = useTranslation();
  const { currentFilters, setFilterOption } = useFilter();
  const [isExpanded, setExpanded] = useState(false);
  const filterOptions = useOverallProgressFilters(filterTypeKey);

  return (
    <div className="filter-group-filter">
      <div
        className="filter-group-filter__header"
        role="button"
        tabIndex={0}
        onClick={() => setExpanded((prev) => !prev)}
      >
        <p className="filter-group-filter__title">{title}</p>
        <div className="filter-group-filter__arrow-icon">
          <SVG src={isExpanded ? CollapseIcon : ExpandIcon}>
            {isExpanded ? t('Collapse') : t('Expand')}
          </SVG>
        </div>
      </div>
      <ul
        className={`filter-group-filter__options ${
          isExpanded && 'filter-group-filter__options--expanded'
        }`}
      >
        {filterOptions.map((item) => (
          <div key={item?.key} className="filter-group-filter__option">
            {item?.value !== 'Video Classroom' && (
              <>
                <Checkbox
                  isChecked={currentFilters[filterTypeKey]?.[item.key]}
                  id={`filter-option-${filterTypeKey}-${item?.key}`}
                  name={item?.key}
                  onChange={(e, value) => {
                    setFilterOption(filterTypeKey, item?.key, value);
                  }}
                  label={item?.value}
                />
                <small className="filter-control-badge">{item?.count}</small>
              </>
            )}
          </div>
        ))}
      </ul>
    </div>
  );
});

Filter.propTypes = {
  filterTypeKey: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

FilterGroup.Filter = Filter;

export default FilterGroup;
