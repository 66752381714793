import { action, observable, computed } from 'mobx';
import { getTechOverview } from 'services/TechOverviewService';
import { KALTURA_CONFIGS } from 'config/constants';

class TechOverviewStore {
  @observable payload = null;

  @observable isLoading = true;

  @observable slug = '';

  @observable versions = [];

  constructor(catalogStore, slug) {
    this.catalogStore = catalogStore;
    this.slug = slug;
  }

  @action getTechOverview = async (slug) => {
    this.isLoading = true;
    try {
      const payload = await getTechOverview(slug);
      if (payload) {
        this.payload = payload; // this may change
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.isLoading = false;
    }
    return this.payload;
  };

  @computed get isLatestVersion() {
    return (
      this.slug === this.catalogStore.getLatestVersion(this.slug.split('-')[0])
    );
  }

  @computed get title() {
    return this.payload?.title;
  }

  @computed get videoEntryId() {
    if (
      Array.isArray(this.payload?.kaltura?.videos) &&
      this.payload?.kaltura?.videos.length > 0
    ) {
      return this.payload?.kaltura?.videos[0];
    }
    return null;
  }

  @computed get playerPartnerId() {
    return this.payload?.partner_id || KALTURA_CONFIGS.partnerId;
  }

  @computed get playerUIConfigId() {
    return (
      this.payload?.custom_player_id ||
      KALTURA_CONFIGS.techOverviewPlayer.uiConfId
    );
  }

  @computed get isPlaylist() {
    return Boolean(this.payload?.is_playlist);
  }

  @action setVersions() {
    this.versions = this.catalogStore.getSortedVersions(
      this.slug.split('-')[0],
    );
    return this.versions;
  }
}

export default TechOverviewStore;
