import { get, post } from 'axios';

import {
  COURSE_PROGRESS,
  EXTERNAL_SUBS_COURSE_PROGRESS,
  PROGRESS_API_URL,
  USER_PROGRESS_TOUCH_API,
} from '../config/constants';

// eslint-disable-next-line
export const getProgress = () => {
  return get(PROGRESS_API_URL).then((res) => res.data);
};

// Input: list of user usernames
// output: aggregated list of course progress data
export const getReporteesProgress = async (users) => {
  try {
    const request = { users };
    const response = await post(`${PROGRESS_API_URL}me/users/`, request);
    return response.data.batch_progress;
  } catch (err) {
    throw err;
  }
};

export const setCourseProgress = (slug, pageUUID) =>
  new Promise((resolve, reject) => {
    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
    };

    const data = {
      course_slug: slug,
      entry_uuid: pageUUID,
      progress: 1,
      score: 1,
    };

    post(`${COURSE_PROGRESS}`, data, config)
      .then((res) => {
        resolve(res.data.result);
      })
      .catch((error) => reject(error));
  });

export const setCourseProgressExternalSubscription = (
  courseCode,
  progress,
  externalSubscription,
) =>
  new Promise((resolve, reject) => {
    const config = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      },
    };

    const data = {
      course_code: courseCode,
      progress,
      subscription: externalSubscription,
    };

    post(`${EXTERNAL_SUBS_COURSE_PROGRESS}`, data, config)
      .then((res) => {
        resolve(res.data.result);
      })
      .catch((error) => reject(error));
  });

export const touchProgressDocument = async (courseSlug) => {
  try {
    const request = { slug: courseSlug };
    await post(USER_PROGRESS_TOUCH_API, request);
  } catch (err) {
    throw err;
  }
};
