import { get, post } from 'axios';
import {
  LAB_DEFINITION_API,
  RAVELLO_LAB,
  STUCK_LABS_API_URL,
  LAB_SSH_KEY_REQUEST,
} from '../config/constants';

const startLab = (command, courseSlug, blueprintId, applicationId) =>
  new Promise((resolve, reject) => {
    const config = {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
      },
      processData: false,
      dataType: 'json',
    };
    const params = {
      command,
      application_id: applicationId,
      course_slug: courseSlug,
      blueprint_id: blueprintId,
    };
    post(RAVELLO_LAB, params, config)
      .then((result) => resolve(result))
      .catch((e) => reject(e));
  });

const getLabDefinition = async (courseSlug) => {
  const url = `${LAB_DEFINITION_API}${courseSlug}`;
  return get(url)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

const getStuckLabs = async () => {
  try {
    const response = await get(STUCK_LABS_API_URL);
    const labs = response.data;
    return labs.items;
  } catch (err) {
    throw err;
  }
};

const getLabSSHkey = async (zdocId) => {
  const url = LAB_SSH_KEY_REQUEST.replace('<user_credential_zdoc_id>', zdocId);
  return get(url)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export { getLabDefinition, getStuckLabs, startLab, getLabSSHkey };
export default startLab;
