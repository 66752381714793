import React, { useContext, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { withErrorBoundary } from 'react-error-boundary';
import { Grid, GridItem, Tooltip, Button } from '@patternfly/react-core';
import NavigationIcon from '@patternfly/react-icons/dist/esm/icons/location-arrow-icon';
import CheckIcon from '@patternfly/react-icons/dist/esm/icons/check-square-icon';
import VideoIcon from '@patternfly/react-icons/dist/esm/icons/video-icon';
import PageIcon from '@patternfly/react-icons/dist/esm/icons/book-open-icon';
import { InfoCircleIcon } from '@patternfly/react-icons';
import CountUp from 'react-countup';
import moment from 'moment';
import { useReactToPrint } from 'react-to-print';
import SVG from 'react-inlinesvg';
import { storesContext } from 'stores';
import ExpandableSection from 'components/ExpandableSection';
import ComponentError from 'components/Error/ComponentError';
import { handleError } from 'services/ErrorService';
import PDFIcon from 'img/icons/pdf-download.svg';
import { useFeature } from '@ole-ui/ole-ui-components';

import ActivityChartFrame from './ActivityChartFrame';
import TechnologyChartsFrame from './TechnologyChartsFrame';
import TrainingHoursChartFrame from './TrainingHoursChartFrame';
import CompletedCoursesChartFrame from './CompletedCoursesChartFrame';
import CompletedExamsChartFrame from './CompletedExamsChartFrame';
import CourseProgress from './CourseProgress';
import ManagerProgress from './ManagerProgress';

import './styles.scss';

const SummaryDashboard = () => {
  const { t, i18n } = useTranslation();
  const componentRef = useRef();
  const openPrintDialog = useReactToPrint({
    content: () => componentRef.current,
  });
  const {
    userStore,
    examsStore,
    organizationStore,
    catalogStore,
    vocabularyStore,
  } = useContext(storesContext);
  const {
    organization,
    usageSummary,
    kalturaVideosWatched,
    totalHoursTimeSeries,
    isTotalHoursTSLoading,
    courseCompletionTimeSeries,
    isCourseCompletionTSLoading,
    examCompletionTimeSeries,
    isExamCompletionTSLoading
  } = organizationStore;

  const isTrainingHoursChartEnabled = useFeature({
    section: 'mgmt_overview',
    flagName: 'training_hours_chart',
    defaultFlagValue: false,
  });

  const isCompletedCoursesChartEnabled = useFeature({
    section: 'mgmt_overview',
    flagName: 'completed_courses_chart',
    defaultFlagValue: false,
  });

  const isCompletedExamsChartEnabled = useFeature({
    section: 'mgmt_overview',
    flagName: 'completed_exams_chart',
    defaultFlagValue: false,
  });

  useEffect(() => {
    if (organization.id) {
      catalogStore.getAllCatalogEntries(false);
      try {
        organizationStore.getUsageSummary(
          i18n,
          vocabularyStore,
          undefined,
          isTrainingHoursChartEnabled,
          isCompletedCoursesChartEnabled,
          isCompletedExamsChartEnabled,
        );
      } catch (e) {
        console.error(e);
      }
    }
  }, [organization.id, i18n, vocabularyStore]);

  const handlePrint = () => {
    if (!organizationStore.isUsageSummaryLoading) {
      openPrintDialog();
    }
  };

  return (
    <>
      <div className="dashboard-summary-download-menu">
        <Tooltip content={t('Download PDF')} entryDelay={0} exitDelay={0}>
          <span
            onClick={handlePrint}
            disabled={organizationStore.isUsageSummaryLoading}
            role="button"
            tabIndex="0"
            aria-label={t('Print summary as PDF')}
          >
            <SVG src={PDFIcon} title="Print summary as PDF" />
          </span>
        </Tooltip>
      </div>

      <div className="dashboard-wrapper" ref={componentRef}>
        <Grid hasGutter>
          <GridItem span={12} lg={3} className="mini-card print-span-3">
            <div className="card-data">
              <div className="card-icon icon-1">
                <NavigationIcon size="md" />
              </div>
              <div className="card-info">
                {!userStore.usageSummary ? (
                  <p>{t('Loading content...')}</p>
                ) : (
                  <React.Fragment>
                    <span className="bold-data">
                      <CountUp
                        start={0}
                        end={usageSummary.total_courses}
                        duration={1}
                      />
                    </span>
                    <span>{t('Courses Started').toUpperCase()}</span>
                  </React.Fragment>
                )}
              </div>
            </div>
          </GridItem>

          <GridItem span={12} lg={3} className="mini-card print-span-3">
            <div className="card-data">
              <div className="card-icon icon-2">
                {/* <Icon.CheckSquare /> */}
                <CheckIcon size="md" />
              </div>
              <div className="card-info">
                {!userStore.usageSummary ? (
                  <p>{t('Loading content...')}</p>
                ) : (
                  <React.Fragment>
                    <span className="bold-data">
                      <CountUp
                        start={0}
                        end={usageSummary.completed_courses}
                        duration={1}
                      />
                    </span>
                    <span>{t('Courses Completed').toUpperCase()}</span>
                  </React.Fragment>
                )}
              </div>
            </div>
          </GridItem>

          <GridItem span={12} lg={3} className="mini-card print-span-3">
            <div className="card-data">
              <Tooltip
                aria-label="Basic tooltip"
                content={t(
                  'Total number of videos all users have started watching in the Learning Subscription',
                )}
                entryDelay={0}
                exitDelay={0}
              >
                <Button
                  className="videos-info-button"
                  variant="plain"
                  icon={
                    <InfoCircleIcon color="var(--pf-v5-global--info-color--100)" />
                  }
                />
              </Tooltip>
              <div className="card-icon icon-5">
                <VideoIcon size="md" />
              </div>
              <div className="card-info">
                {!userStore.usageSummary ? (
                  <p>{t('Loading content...')}</p>
                ) : (
                  <React.Fragment>
                    <span className="bold-data">
                      <CountUp
                        start={0}
                        end={usageSummary.vc_courses + kalturaVideosWatched}
                        duration={1}
                      />
                    </span>
                    <span>{t('Videos Watched').toUpperCase()}</span>
                  </React.Fragment>
                )}
              </div>
            </div>
          </GridItem>

          <GridItem span={12} lg={3} className="mini-card print-span-3">
            <div className="card-data">
              <Tooltip
                aria-label="Basic tooltip"
                content={t(
                  'Total number of live training sessions all users have attended',
                )}
                entryDelay={0}
                exitDelay={0}
              >
                <Button
                  className="premium-info-button"
                  variant="plain"
                  icon={
                    <InfoCircleIcon color="var(--pf-v5-global--info-color--100)" />
                  }
                />
              </Tooltip>
              <div className="card-icon icon-4">
                <PageIcon size="md" />
              </div>
              <div className="card-info">
                {!userStore.usageSummary ? (
                  <p>{t('Loading content...')}</p>
                ) : (
                  <React.Fragment>
                    <span className="bold-data">
                      <CountUp
                        start={0}
                        end={usageSummary.attended_sessions}
                        duration={1}
                      />
                    </span>
                    <span>{t('Premium Sessions').toUpperCase()}</span>
                  </React.Fragment>
                )}
              </div>
            </div>
          </GridItem>
          <GridItem span={12} lg={3} className="print-span-3">
            <div className="medium-card">
              <div className="medium-card-data">
                <div className="medium-card-info">
                  <div className="medium-card-display">
                    <div className="exam-data-wrapper">
                      {!examsStore?.exams || !usageSummary?.exams ? (
                        t('Loading content...')
                      ) : (
                        <React.Fragment>
                          <div className="exam-data-holder">
                            <span>{t('SCHEDULED').toUpperCase()}</span>
                            <span className="exam-data bold-data">
                              {`${
                                (usageSummary.exams.scheduled || 0) +
                                (usageSummary.exams.retake_scheduled || 0)
                              }`}
                            </span>
                          </div>
                          <div className="exam-data-holder">
                            <span>{t('UNSCHEDULED').toUpperCase()}</span>
                            <span className="exam-data bold-data">
                              {`${
                                (usageSummary.exams.unscheduled || 0) +
                                (usageSummary.exams.retake_available || 0)
                              }`}
                            </span>
                          </div>
                          <div className="exam-data-holder">
                            <span>{t('CANCELED').toUpperCase()}</span>
                            <span className="exam-data bold-data">
                              {`${usageSummary.exams.canceled || 0}`}
                            </span>
                          </div>
                          <div className="exam-data-holder">
                            <span>{t('COMPLETED').toUpperCase()}</span>
                            <span className="exam-data bold-data">
                              {`${
                                (usageSummary.exams.completed || 0) +
                                (usageSummary.exams.retake_complete || 0) +
                                (usageSummary.exams.retake_available || 0) +
                                (usageSummary.exams.retake_scheduled || 0) +
                                (usageSummary.exams.retake_results_pending ||
                                  0) +
                                (usageSummary.exams.results_pending || 0)
                              }`}
                            </span>
                          </div>
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                  <div className="medium-card-bottom">
                    {t('Exams').toUpperCase()}
                  </div>
                </div>
              </div>
            </div>
          </GridItem>
          <GridItem span={12} lg={3} className="print-span-3">
            <div className="medium-card">
              <div className="medium-card-data">
                <div className="medium-card-info">
                  <div className="medium-card-display">
                    <div className="lab-hours-summary-wrapper">
                      <React.Fragment>
                        <p className="giant-nr">
                          {`${Math.round(usageSummary.training_hours) || 0}`}
                        </p>
                      </React.Fragment>
                    </div>
                  </div>
                  <div className="medium-card-bottom">
                    {t('Training hours').toUpperCase()}
                  </div>
                </div>
              </div>
            </div>
          </GridItem>
          <GridItem span={12} lg={3} className="print-span-3">
            <div className="medium-card">
              <div className="medium-card-data">
                <div className="medium-card-info">
                  <div className="medium-card-display">
                    <div className="lab-hours-summary-wrapper">
                      <React.Fragment>
                        <p className="giant-nr">
                          {`${Math.round(usageSummary.lab_hours) || 0}`}
                        </p>
                      </React.Fragment>
                    </div>
                  </div>
                  <div className="medium-card-bottom">
                    {t('Lab hours').toUpperCase()}
                  </div>
                </div>
              </div>
            </div>
          </GridItem>
          <GridItem span={12} lg={3} className="print-span-3">
            <div className="medium-card">
              <div className="medium-card-data">
                <div className="medium-card-info">
                  <div className="medium-card-display">
                    <div className="lab-hours-summary-wrapper">
                      <React.Fragment>
                        <span className="inline-count-up bold-data">
                          {usageSummary.average_completion_hours
                            ? moment
                                .duration(
                                  usageSummary.average_completion_hours,
                                  'hours',
                                )
                                .humanize()
                            : t('Not applicable')}
                        </span>
                      </React.Fragment>
                    </div>
                  </div>
                  <div className="medium-card-bottom">
                    {t('Average course completion').toUpperCase()}
                  </div>
                </div>
              </div>
            </div>
          </GridItem>
          <GridItem span={12} lg={6} className="print-span-6">
            <div className="medium-card">
              <div className="medium-card-data">
                <ActivityChartFrame
                  studentActivity={usageSummary.student_activity}
                />
              </div>
            </div>
          </GridItem>
          <GridItem span={12} lg={6} className="print-span-6">
            <div className="medium-card">
              <div className="medium-card-data">
                <TechnologyChartsFrame
                  products={usageSummary.products}
                  categories={usageSummary.categories}
                />
              </div>
            </div>
          </GridItem>
          {isTrainingHoursChartEnabled && (
            <GridItem span={12} lg={12} className="print-span-12">
              <div className="medium-card">
                <div className="medium-card-data">
                  <TrainingHoursChartFrame
                    labHours={totalHoursTimeSeries?.lab_hours}
                    trainingHours={totalHoursTimeSeries?.training_hours}
                    isLoading={isTotalHoursTSLoading}
                  />
                </div>
              </div>
            </GridItem>
          )}
          {isCompletedCoursesChartEnabled && (
            <GridItem span={12} lg={12} className="print-span-12">
              <div className="medium-card">
                <div className="medium-card-data">
                  <CompletedCoursesChartFrame
                    completedCourses={
                      courseCompletionTimeSeries?.completed_courses
                    }
                    isLoading={isCourseCompletionTSLoading}
                  />
                </div>
              </div>
            </GridItem>
          )}
          {isCompletedExamsChartEnabled && (
            <GridItem span={12} lg={12} className="print-span-12">
              <div className="medium-card">
                <div className="medium-card-data">
                  <CompletedExamsChartFrame
                    completedExams={examCompletionTimeSeries}
                    isLoading={isExamCompletionTSLoading}
                  />
                </div>
              </div>
            </GridItem>
          )}
        </Grid>

        <div className="medium-card mt-16">
          <div className="medium-card-data">
            <ExpandableSection
              localStorageStateKey="courseProgressTableCollapseState"
              title={t('Overall course progress')}
              color="light"
            >
              <CourseProgress
                isLoading={organizationStore.isUsageSummaryLoading}
                progressPerCourse={
                  organizationStore.progressPerCoursesWithNames
                }
                setFilters={organizationStore.setProgressPerCourseFilters}
              />
            </ExpandableSection>
          </div>
        </div>

        {organizationStore.summaryType === 'current' &&
          organizationStore.isAdminOfCurrentlySelectedOrg && (
            <div className="medium-card mt-16">
              <div className="medium-card-data">
                <ExpandableSection
                  localStorageStateKey="managerProgressTableCollapseState"
                  title={t('Managers')}
                  color="light"
                >
                  <ManagerProgress
                    isLoading={organizationStore.isUsageSummaryLoading}
                    data={organizationStore.filteredManagerProgress}
                    filterValue={organizationStore.managerProgressFilter}
                    onFilterChange={(value) =>
                      organizationStore.setManagerProgressFilter(value)
                    }
                  />
                </ExpandableSection>
              </div>
            </div>
          )}
        <div className="dashboard-wrapper__print-width-guarantee" />
      </div>
    </>
  );
};

export default withErrorBoundary(
  observer(SummaryDashboard),
  ComponentError,
  handleError,
);
