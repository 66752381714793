import React from 'react';
import PropTypes from 'prop-types';
import { toUpper } from 'lodash';

function CourseNameCell({ row, cell }) {
  return (
    <div>
      <span className="course-progress__table-row-number">{`${
        row?.index + 1
      }.`}</span>
      {`${toUpper(row?.original?.id)} - ${cell?.value}`}
    </div>
  );
}

CourseNameCell.propTypes = {
  row: PropTypes.object.isRequired,
  cell: PropTypes.object.isRequired,
};

export default CourseNameCell;
