import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { CenteredLoadingSpinner, Table } from '@ole-ui/ole-ui-components';
import FilterGroup from 'components/FilterGroup';
import CourseNameCell from './CourseNameCell';

import './styles.scss';

const paginationOptions = {
  widgetId: 'management-dashboard-course-progress-pagination-options-menu',
  perPageOptions: [
    { title: '5', value: 5 },
    { title: '10', value: 10 },
    { title: '25', value: 25 },
  ],
};

function CourseProgress({ isLoading, progressPerCourse, setFilters }) {
  const { t } = useTranslation();

  const columnDefinitions = useMemo(
    () => [
      {
        Header: t('Course'),
        accessor: 'course_title',
        Cell: CourseNameCell,
      },
      {
        Header: t('Completed'),
        accessor: 'completed',
        patternflyThProps: { modifier: 'fitContent' },
      },
      {
        Header: t('In progress'),
        accessor: 'in_progress',
        patternflyThProps: { modifier: 'fitContent' },
      },
      {
        Header: t('Started'),
        accessor: 'started',
        patternflyThProps: { modifier: 'fitContent' },
      },
      {
        Header: t('Not started'),
        accessor: 'not_started',
        patternflyThProps: { modifier: 'fitContent' },
      },
    ],
    [],
  );

  if (!progressPerCourse) return null;

  if (isLoading) return <CenteredLoadingSpinner />;

  return (
    <div className="course-progress">
      <div className="course-progress__filters">
        <FilterGroup
          onFilterChange={(filters) => {
            setFilters(filters);
          }}
        >
          <FilterGroup.Filter filterTypeKey="products" title={t('Products')} />
          <FilterGroup.Filter
            filterTypeKey="categories"
            title={t('Categories')}
          />
        </FilterGroup>
      </div>
      <div className="course-progress__table-container">
        <Table
          ariaLabel={t(
            'Table containing breakdown of course usage and completion by the users at your organization',
          )}
          className="course-progress__table"
          data={progressPerCourse}
          columns={columnDefinitions}
          paginationOptions={paginationOptions}
          isStriped
        />
      </div>
    </div>
  );
}

CourseProgress.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  progressPerCourse: PropTypes.array,
  setFilters: PropTypes.func.isRequired,
};

CourseProgress.defaultProps = {
  progressPerCourse: [],
};

export default CourseProgress;
