export const CERTIFICATE_MAGIC_KEY = '4c888a9d';

export const SENTRY_PUBLIC_KEY =
  'https://b1998358d8414b84b4377a57917dc1c7@o490301.ingest.sentry.io/4504158554030080';

// API endpoints
export const BASE = `/${window.location.pathname.split('/')[1]}`;
export const HOSTNAME = `${window.location.hostname}`;
export const APP_BASE = `${BASE}/app`;
export const BUNDLED_COURSE_BASE = `${BASE}/content`;
export const COURSE_BASE = `${BASE}/rhz/rhls/course`;
export const API_PREFIX = 'api';
export const SAPI_PREFIX = 'sapi';

export const WEBPACK_PUBLIC_PATH = `${BASE}/static/www/`;

export const API_BASE = `${BASE}/${API_PREFIX}/`;
export const API_LOGIN = `${API_BASE}login/`;
export const API_LOGOUT = `${API_BASE}logout/`;
export const API_IMPERSONATION = `${API_LOGIN}imp`;

export const SSO_LOGIN_API = `${BASE}/auth_idp/@@login/`;

export const SAPI_BASE = `${BASE}/${SAPI_PREFIX}/rest/`;
export const SAPI_BASE_ZDOCR = `${BASE}/${SAPI_PREFIX}/zdocr/`;

export const RHZ_BASE = `${BASE}/rhz/`;
export const RHZ_API_BASE = `${RHZ_BASE}api/`;

export const API_EVENT = `${SAPI_BASE}event/`;

export const SERVER_CONFIG_API = `${SAPI_BASE}configuration`;
export const FEATURE_SERVER_CONFIG_API = `${SAPI_BASE}configuration/features/`;

export const OPS_BASE = `${BASE}/ops/`;
export const OPS_API_BASE = `${OPS_BASE}api/`;
export const OPS_ENROLLMENT_SEARCH_VIEW = `${OPS_BASE}enrollment_search`;
export const OPS_ACCOUNTS_VIEW = `${OPS_BASE}account`;
export const OPS_COURSES_VIEW = `${OPS_BASE}course`;
export const OPS_CLASSES_VIEW = `${OPS_BASE}vtclass`;
export const OPS_JOBS_URL = `${OPS_API_BASE}jobs`;
export const OPS_RHLC_USERS_VIEW = `${RHZ_BASE}lithium`;
export const OPS_CONTENT_MANAGER_VIEW = `${RHZ_BASE}admin/content_mgr`;
export const OPS_SCHEMA_VIEW = `${RHZ_BASE}admin/content_mgr`;
export const OPS_DJANGO_ADMIN_VIEW = `${BASE}/admin/`;

export const FEEDBACK_URL = `${BASE}/feedback/`;
export const SUPPORT_API_URL = `${OPS_API_BASE}support-case/`;

export const CATALOG_ENTRIES_API = `${SAPI_BASE}catalog_entries/`;
export const COURSES_API = `${API_BASE}courses/`;
export const COURSE_CATALOG_API = `${SAPI_BASE}catalog/`;
export const COURSE_CATALOG_LEGACY_API = `${RHZ_API_BASE}offerings/`;
export const COURSE_COLLATERAL_API = `${SAPI_BASE}course_collateral/`;
export const EXAM_COLLATERAL_API_URL = `${SAPI_BASE}exam_collateral/`;
export const TECH_OVERVIEW_COLLATERAL_API_URL = `${SAPI_BASE}tech_overview_collateral/`;
export const VOCABULARY_API = `${SAPI_BASE}vocabulary/`;
export const LAB_DEFINITION_API = `${SAPI_BASE}lab_definition2/`;
export const USER_LAB_API = `${SAPI_BASE}user_lab2/`;
export const INHIBIT_LAB_START_API = `${SAPI_BASE}inhibit_lab_start/`;

export const COURSE_INFO_API = `${RHZ_API_BASE}course_info/<course-slug>`;
export const COURSE_INFO_WITH_LABS_API = `${COURSE_INFO_API}?product=<product>`;
export const COURSE_INFO_SIMPLE_API = `${RHZ_API_BASE}course-info/<course-slug>/`;

export const EBOOK_API_BASE_V2 = `${RHZ_API_BASE}ebook/v2.0/document/`;

export const ROSTER_API_BASE = `${API_BASE}roster/`;
export const OFFERINGS_API_BASE = `${ROSTER_API_BASE}offerings/`;

export const CLASSES_VIEW_BASE = `${RHZ_BASE}classes/`;
export const CLASSES_API_BASE = `${API_BASE}classes/`;
export const CLASSES_CONFERENCE_API = `${CLASSES_API_BASE}<uuid>/conference/`;
export const CLASS_ENROLLMENTS_API = `${CLASSES_API_BASE}<uuid>/enrollments/`;
export const CLASS_ENROLLMENT_API = `${CLASS_ENROLLMENTS_API}<euuid>`;
export const CLASS_ENROLLMENT_JOIN_API = `${CLASS_ENROLLMENT_API}/join`;

export const LMS_CLASSES_API_BASE = `${API_BASE}lms_classes/`;
export const ILT_CLASS_DETAILS_VIEW_BASE = `${RHZ_BASE}lms_class/`;

export const VT_CLASS_VIEW_BASE = `${BASE}/es/vt/`;
export const VT_CLASS_VIEW = `${CLASSES_VIEW_BASE}<uuid>`;
export const VT_CLASS_NEW = `${CLASSES_VIEW_BASE}new/`;
export const VT_CLASS_EDIT = `${VT_CLASS_VIEW}/edit/`;
export const VT_LAB_API_BASE = `${VT_CLASS_VIEW_BASE}<uuid>/api`;
export const VTC_BASE = `${BASE}/vtc/`;

export const VT_CLASS_SETTINGS_API = `${SAPI_BASE}vtclasses/`;

export const VTC_ACCOUNTS_API = `${API_BASE}vtc_accounts/`;

export const FREE_TIER_VC_API = `${SAPI_BASE}vc_free_tier/`;

export const RHLC_BASE = 'https://learn.redhat.com/';

export const SUBSCRIPTION_API = `${SAPI_BASE}subscriptions/`;

// User APIs
// TODO: USERS_API is being deprecated
export const USERS_API = `${OPS_API_BASE}users/`;
export const USER_RECORD_API = `${SAPI_BASE}users/`;
export const CURRENT_USER_RECORD_API = `${USER_RECORD_API}me`;
export const USER_GROUPS_API = `${USER_RECORD_API}?group=<group>`;
export const USER_SUBSCRIPTION_API = `${USER_RECORD_API}<uuid>/current_subscription`;
export const MANAGEMENT_REPORTING_API_BASE = `${SAPI_BASE}organization`;

// Partner APIs
export const PARTNER_API = `${SAPI_BASE}training_partner/?`;

export const GENERATE_CERTIFICATES_OF_ATTENDANCE = `${RHZ_BASE}certificates/attendance/generate/`;
export const CERTIFICATE_OF_ATTENDANCE_RHLC_URL = `${RHLC_BASE}t5/custom/page/page-id/certificate-of-attendance`;
export const CERTIFICATE_OF_ATTENDANCE_PREMIUM_RHLC_URL = `${RHLC_BASE}t5/custom/page/page-id/prvt-certificate-of-attendance`;
export const CERTIFICATE_OF_ATTENDANCE_BASE_API = `${API_BASE}certificates/attendance`;
export const CERTIFICATE_OF_ATTENDANCE_UUID_API = `${API_BASE}certificates/attendance/uuid/`;
export const CERTIFICATE_OF_ATTENDANCE_DOWNLOAD_API = `${API_BASE}certificates/attendance/uuid/<uuid>`;
export const CERTIFICATE_OF_ATTENDANCE_ENCODED_API = `${CERTIFICATE_OF_ATTENDANCE_BASE_API}/<data>`;
export const CERTIFICATE_OF_ATTENDANCE_VTCLASS = `${API_BASE}enrollments/<euuid>/certificates/attendance`;
export const CERTIFICATE_OF_ATTENDANCE_OFFERING = `${LMS_CLASSES_API_BASE}<offeringId>/enrollments/<username>/certificates/attendance`;
export const SEND_SURVEY_URL = `${LMS_CLASSES_API_BASE}<offeringId>/enrollments/<username>/survey/`;
export const ROSTER_DOWNLOAD_URL = `${CLASSES_API_BASE}<uuid>/enrollments/?download`;

export const RHLS_GROUP_URL = `${RHLC_BASE}t5/Red-Hat-Learning-Subscription/gh-p/RedHatLearningSubscriptionGroup`;

export const TRAINING_CONTACTS =
  'https://www.redhat.com/en/services/training/global-contacts';
export const FREE_TIER_CONTACT_US =
  'https://www.redhat.com/en/services/training-and-certification/contact-us';
export const VT_FAQ_URL =
  'https://www.redhat.com/en/services/training/docs/virtual-classroom-faq';

export const SEARCH_API = `${BASE}/sapi/search/`;
export const MANAGE_ACCOUNT =
  'https://www.redhat.com/wapps/ugc/protected/account.html';
export const REGISTER = 'https://www.redhat.com/join';
export const CUSTOMER_SERVICE =
  'https://access.redhat.com/support/contact/customerService/';
export const NOTIFICATION_API = `${SAPI_BASE}notifications/`;

export const ENROLLMENT_API = `${API_BASE}courses/<course-slug>/enrollments/<username>`;
export const ENROLLMENT_INSTANCE = `${SAPI_BASE}enrollments/<uuid>/`;
export const COURSE_PROGRESS = `${RHZ_API_BASE}progress`;
export const EXTERNAL_SUBS_COURSE_PROGRESS = `${API_BASE}sso/progress`;
export const RAVELLO_LAB = `${RHZ_API_BASE}ravello_lab`;
export const COURSE_HELP =
  'https://www.redhat.com/en/services/training/docs/red-hat-online-learning-faq';
export const COURSE_STRUCTURE_API = `${API_BASE}courses/<course-slug>/structure`;
export const COURSE_PAGE = `${API_BASE}courses/<course-slug>/pages/<page>`;
export const VC_PLAYER = `${VTC_BASE}rvideo_class_player`;
export const INTERACTIVE_VIEW_BASE = `${BASE}/es/api`;
export const INTERACTIVE_API = `${INTERACTIVE_VIEW_BASE}/interactive/<course-slug>/<chapter-name>`;
export const KALTURA_SESSION_API = `${BASE}/${SAPI_PREFIX}/kaltura_session/`;

export const PRIVACY_POLICY_URL =
  'https://www.redhat.com/en/about/privacy-policy';

export const PREFERENCE_UNSUBSCRIBE =
  'https://engage.redhat.com/Global-Preference-Center#unsubscribe_here';

export const TRAINING_POLICIES_URL =
  'http://s.bl-1.com/h/cZrb2DXG?url=https://www.redhat.com/en/about/red-hat-training-policies';

export const TERMS_OF_USE_URL = 'https://www.redhat.com/en/about/terms-use';

export const ALL_POLICIES_URL =
  'https://www.redhat.com/en/about/all-policies-guidelines';
export const RELEASE_NOTES_URL =
  'https://learn.redhat.com/t5/Red-Hat-Learning-Subscription/bg-p/RedHatLearningSubscriptionGroupblog-board';

export const REDHAT_COM_URL = 'http://www.redhat.com';
export const ACCESS_REDHAT_COM_URL = 'https://access.redhat.com';

export const FREE_TRIAL_UPGRADE_PAGE =
  'https://www.redhat.com/en/services/training/learning-subscription?intcmp=701f2000000u5R8AAI';

export const USER_SUBSCRIPTION_API_BASE = `${SAPI_BASE}user_subscription/`;

export const CUSTOMER_ORG_API_BASE = `${SAPI_BASE}customer_org/`;
export const COURSE_COMPLETION_REPORTING_API_URL = `${OPS_API_BASE}reports/course/completion/`;
export const COURSE_ACTIVITY_REPORTING_API_URL = `${OPS_API_BASE}reports/course/activity_hist2/`;
export const LAB_ACTIVITY_REPORTING_API_URL = `${OPS_API_BASE}reports/lab/activity/`;
export const EXAM_ACTIVITY_REPORTING_API_URL = `${OPS_API_BASE}reports/exam/activity/`;

export const TECH_OVERVIEW_API_URL = `${SAPI_BASE}tech_overview/`;
export const EXPERT_SEMINAR_API_URL = `${SAPI_BASE}expert_seminar/`;
export const EXAM_SCHEDULER_API_URL = `${SAPI_BASE}user_exam/`;
export const LEARNING_PATH_API_URL = `${SAPI_BASE}learning_path/`;
export const PROGRESS_API_URL = `${SAPI_BASE}progress/`;
export const EXAM_STATUS_MANAGEMENT_API_URL = `${EXAM_SCHEDULER_API_URL}me/users/`;

export const FEEDBACK_FORM_URL = `https://forms.gle/u7uYokxKLTCEGLHMA`;
export const RHU_SUPPORT_URL = `https://redhat.service-now.com/help?id=sc_cat_item&sys_id=fd7112e787694514594721f6cebb3596`;
export const JWPLAYER_CLOUD_URL = 'https://cdn.jwplayer.com/players/';
export const USERINFO_API_URL = `${SAPI_BASE}userinfo/`;
export const ADMIN_REPORTING_API_BASE = `${SAPI_BASE}reports/`;
export const REPORT_MANAGEMENT_API_BASE = `${OPS_API_BASE}reports/search/`;
export const GENERATED_REPORT_API_BASE = `${OPS_API_BASE}reports/generated_report/`;
export const SCHEDULED_REPORT_JOB_MANAGEMENT_API_BASE = `${OPS_API_BASE}reports/scheduled_report/`;
export const SCHEDULED_REPORT_JOB_REPORTS_MANAGEMENT_API_BASE = `${OPS_API_BASE}reports/generated_report/`;
export const RHT_PASSWORD_RECOVERY = `https://sso.redhat.com/auth/realms/redhat-external/login-actions/reset-credentials`;
export const DASHBOARD_FILTER_STATUSES = {
  exam: [
    'scheduled',
    'unscheduled',
    'retake_available',
    'retake_scheduled',
    'completed',
    'retake_complete',
    'requested',
    'results_pending',
    'retake_results_pending',
  ],
  course: ['in_progress', 'completed', 'not_started'],
  learning_paths: ['in_progress', 'completed', 'not_started'],
  lab: [],
  video: ['in_progress', 'completed', 'not_started'],
  certification: [],
  download: [],
  prem_vt_class: ['in_progress', 'completed'],
  prem_vt_enrollment: ['attended', 'scheduled_premium'],
  tech_overview: ['in_progress', 'completed'],
};
export const DASHBOARD_SORT_FILEDS = {
  title: 'title',
  lastAccessedDate: '@timestamp',
};
export const ALERTS_API_URL = `${SAPI_BASE}alerts/`;

export const STUCK_LABS_API_URL = `${SAPI_BASE}lab_stuck_state`;

// FAQ Partners url
export const FAQ_PARTNERS = `${SAPI_BASE}faq-partners`;

// PRE-DEFINED VALUES
export const DEFAULT_LANGUAGE = 'en_US';
export const COMPLIANCE_BANNER_DUE_DAYS = 30;
export const LAB_USED_COURSE = `${SAPI_BASE}labhours_used/`;
export const API_EVENT_INFO = `${API_EVENT}?event_type=<event-type>&last_events=<last-events>`;

export const LAB_DATA_POLLING_SECONDS = 15;
export const LAB_AUTOSHUTDOWN_POLLING_SECONDS = 60;
export const EVENT_TYPES = {
  CONCURRENT_LOGIN: 'concurrent_login',
  USER_LOGIN: 'user_login',
};

export const EXAM_DEFAULT_VERSION = '1.0';

export const AWS_REPORT_DOWNLOAD_BASE = `http://rht.user-artifacts.s3-website-us-west-2.amazonaws.com/user_activity_report`;

export const SESSION_API_BASE = `${API_BASE}session/`;

export const DRAWER_SECTIONS = [
  { label: 'All', key: 0 },
  { label: 'Support', key: 1 },
  { label: 'Reports', key: 2 },
  { label: 'Other', key: 3 },
];

export const ZDOC_BASE_API = `${SAPI_BASE}zdoc/<zdoc-name>/@@query`;
export const ZDOC_POST_QUERY_API = `${ZDOC_BASE_API}?size=<size>&from=<from>`;

export const PREMVT_SESSION_ENROLLMENT_API = `${SAPI_BASE}premvt_session_enrollment`;
export const PREMVT_SESSION_ENROLLMENT_PARAMS_API = `${PREMVT_SESSION_ENROLLMENT_API}<<query-params>>`;
export const PREMVT_SESSION_ENROLLMENT_PATCH_API = `${PREMVT_SESSION_ENROLLMENT_API}/<<premvt_session_uuid>>`;
export const PREMVT_SESSION_API = `${SAPI_BASE}premvt_session`;
export const PREMVT_SESSION_COLLATERAL_API = `${SAPI_BASE}premvt_session_collateral`;
export const PREMVT_SESSION_CONTENT_API = `${SAPI_BASE}premvt_session_content`;
export const PREMVT_SESSION_ID_ENROLLMENT_API = `${PREMVT_SESSION_API}/<<session_id>>/enrollments<<query-params>>`;
export const PREMVT_SESSION_COMPLETE_API = `${PREMVT_SESSION_API}/<<session_id>>/session_completed`;
export const PREMVT_SESSION_DETAIL_API = `${PREMVT_SESSION_API}/<<session_id>>`;
export const PREMVT_SESSION_API_COA = `${PREMVT_SESSION_API}/certificate_uuid`;
export const PREMVT_SESSION_INSTRUCTORS_DETAILS_API = `${PREMVT_SESSION_API}/instructors`;
export const PREMVT_SESSION_SURVEY_API = `${PREMVT_SESSION_API}/<<session_id>>/survey`;
export const PREMVT_SESSION_SURVEY_NOTIFICATION_ID =
  'survey-premvt-<<session_id>>';
export const PREMVT_INTERESTED_SESSION_API = `${SAPI_BASE}premvt_session_interested`;

export const ZDOC_PREMIUM_VT_SESSION = `premvt_session`;
export const ZDOC_PREMIUM_VT_SESSION_CONTENT = `premvt_session_content`;
export const ZDOC_COURSE_INFO = `course_info`;

export const USER_OFFERING_API = `${SAPI_BASE}user_offering`;

export const USER_NOTIFICATION_API = `${SAPI_BASE_ZDOCR}user_notification/`;
export const GET_USER_NOTIFICATION_API = `${USER_NOTIFICATION_API}@@search`;

export const USER_EXAM_RESOURCE_API = `${SAPI_BASE_ZDOCR}user_exam/`;
export const USER_EXAM_STATUS_API = `${USER_EXAM_RESOURCE_API}@@status`;
export const USER_EXAM_ENROLL_API = `${USER_EXAM_RESOURCE_API}@@enroll`;

export const USER_PROGRESS_RESOURCE_API = `${SAPI_BASE_ZDOCR}user_progress/`;
export const USER_PROGRESS_TOUCH_API = `${USER_PROGRESS_RESOURCE_API}@@touch`;

export const COURSE_METADATA_API = `${SAPI_BASE_ZDOCR}course_metadata/`;

export const ARCHIVE_USER_NOTIFICATION_API = `${USER_NOTIFICATION_API}<notification_uuid>/@@archive`;
export const DELETE_USER_NOTIFICATION_API = `${USER_NOTIFICATION_API}<notification_uuid>`;

export const SORT_ORDER = {
  ASC: 'ascending',
  DESC: 'descending',
  NEUTRAL: 'neutral',
};

export const PREMIUM_SCHEDULED_SESSION_SORT_FILEDS = {
  date: 'start_time',
  time: 'time',
  lastAccessedDate: '@timestamp',
  instructor: 'instructorNames',
  language: 'instruction_language',
  availableSeats: 'seats_available',
};

export const SKILLS_ASSESSMENT_URL = `https://rhtapps.redhat.com/assessment/`;

export const PREMIUM_SESSION_FEEDBACK_URL = `https://docs.google.com/forms/d/15jvGz04GgAlcCeZPjTnvLMl0HEHrZNdmIQWmwQMrb6g/edit?usp=sharing`;

export const PREMIUM_VT_SESSION_STATES = {
  interested: 'interested',
  enrolled: 'enrolled',
  scheduled: 'scheduled',
  cancelled: 'cancelled',
  completed: 'completed',
  active: 'active',
};

export const PREMIUM_VT_SESSION_ERROR_STATES = {
  invalid_state: 'bad specified state',
  invalid_session: 'invalid_session',
  nonscheduled_completed_session: 'premvt session not found',
  enrolled_session: 'enrolled_session',
  capacity_reached: 'capacity_reached',
  conflicting_enrollments: 'conflicting_enrollments',
  schedule_limit_exceeded: 'user sessions scheduled limit exceeded',
  session_limit_exceeded: 'user sessions limit exceeded',
  cannot_cancel_started_session: 'cannot cancel started session',
  subscription_expires_before_schedule:
    'The subscription will expire before the session ends',
  general_error: '',
};

export const PREMIUM_ENROLLMENT_SORT_FILEDS = {
  name: 'name',
  username: 'username',
  email: 'email',
  state: 'state',
};

export const PREMIUM_INSTRUCTOR_DASHBOARD_SORT_FILEDS = {
  startTime: 'start_time',
  sessionTitle: 'sessionTitle',
};

export const PREMIUM_VT_SCHEDULE_SESSION_LIMIT = 20;

export const PREMIUM_VT_SESSION_START_DUE_MINS = 15;
export const PREMIUM_VT_SESSION_START_DUE_MINS_INSTRUCTOR = 30;
export const PREMIUM_VT_SESSION_START_DUE_MINS_TIMER = 60;
export const PREMIUM_VT_SESSION_SEATS_RANGE = {
  full: 'Full',
  limited: 'Limited',
  available: 'Available',
};

export const OLE_ASYNC_REPORTS = {
  byuser: 'by_user',
  by_lms_order_number: 'by_lms_order_number',
  bysub: 'by_subscription',
  by_premvt_sessions: 'premvt_interested',
};

export const COURSE_INFO_META_API = `${SAPI_BASE}course_info/<course-slug>`;

export const LAB_SSH_KEY_REQUEST = `${SAPI_BASE}user_lab2_credential/<user_credential_zdoc_id>`;

export const TRUSTARC_SCRIPT_URL = {
  devel:
    '//static.redhat.com/libs/redhat/marketing/latest/trustarc/trustarc.stage.js',
  stage:
    '//static.redhat.com/libs/redhat/marketing/latest/trustarc/trustarc.stage.js',
  rht: '//static.redhat.com/libs/redhat/marketing/latest/trustarc/trustarc.js',
  china:
    '//static.redhat.com/libs/redhat/marketing/latest/trustarc/trustarc.js',
};

export const TRUSTARC_LIBS_DEF = [
  {
    type: 'link',
    attr: {
      rel: 'stylesheet',
      href: 'https://static.redhat.com/libs/redhat/marketing/latest/trustarc/trustecm/css/trustecm.css',
    },
  },
  {
    type: 'script',
    attr: {
      src: 'https://static.redhat.com/libs/redhat/marketing/latest/trustarc/trustecm/js/trustecm.js',
      'data-domain': 'rol.redhat.com',
      'data-debug': 'true',
      'data-platform': 'react',
    },
  },
  {
    type: 'script',
    attr: {
      'data-src':
        'https://static.redhat.com/libs/redhat/marketing/latest/trustarc/trustecm/js/trustecm-functional.js',
      class: 'trustecm',
      'data-tracker-type': 'functional',
    },
  },
  {
    type: 'script',
    attr: {
      'data-src':
        'https://static.redhat.com/libs/redhat/marketing/latest/trustarc/trustecm/js/trustecm-advertising.js',
      class: 'trustecm',
      'data-tracker-type': 'advertising',
    },
  },
];

export const CUSTOM_SKILL_PATH_MODE = {
  create: 'create',
  edit: 'edit',
  none: 'none',
};

export const USER_SUBSCRIPTION_CODE = {
  ls000: 'ls000 - Free Tier',
  ls101: 'ls101 - Subscription Promo',
  ls105: 'ls105 - Subscription for Partners',
  ls120: 'ls120 - Subscription Basic',
  ls130c: 'ls130c - Subscription Basic for IBM Digital Learning Subscription',
  ls220: 'ls220 - Subscription Standard',
  ls300: 'ls300 - Subscription Developer Tier',
  ls520: 'ls520 - Subscription Premium',
  ls999: 'ls999 - Limited Trial Subscription',
  xs001: 'xs001 - Subscription Skills Build',
  wdp200sp: 'wdp200sp - Subscription Work Force Development Tier',
};

export const KALTURA_CONFIGS = {
  partnerId: 2032581,
  legacyPlayer: {
    uiConfId: 44360621,
  },
  player: {
    uiConfId: 49478072,
  },
  expertExtrasPlayer: {
    uiConfId: 50955442,
  },
  labsTutorial: {
    uiConfId: 52134902,
    entryId: '1_bhftnojl',
  },
  courseContentInlinePlayer: {
    uiConfId: 52286042,
  },
  premiumInFreeTrial: {
    uiConfId: 49478072,
    entryId: '1_hg2c9fsl',
  },
  techOverviewPlayer: {
    uiConfId: '52219432',
  },
  freeTrialTutorial: {
    uiConfId: 52219432,
    entryId: '1_qx0mg5re',
  },
};

export const RHLC_GROUPHUB_MESSAGES = `${SAPI_BASE}lithium_message`;

export const MINIMUM_COURSE_COMPLETION_PERCENTAGE = 75;

// TODO: idea for the future: pendo guide ids should be defined as configs on the back-end allowing for easier management
export const PENDO_GUIDES = {
  CHINA: {
    courseCompletion: 'WzeXIayjGOMqmKrwn1vchISWlsA',
  },
  STANDARD: {
    courseCompletion: '3sqByUEN26WotkRujerQXpt9D5M',
  },
};

export const SSO_PROFILE_EDIT_PAGE =
  'https://www.redhat.com/wapps/ugc/protected/personalInfo.html'; // should we use a config instead?

export const AWS_WAF_CAPTCHA = {
  SCRIPT_SRC_URL:
    'https://b4ea1126f67b.edge.captcha-sdk.awswaf.com/b4ea1126f67b/jsapi.js',
};
